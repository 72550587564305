<template>
	<div class="quill-component">
		<!--  :style="{height: `${height}px`}" -->
		<quill-editor v-model="content" :ref="refName" v-if="type == 1" class="quill-content" :options="editorOption">
		</quill-editor>

		<quill-editor v-model="content" :ref="refName" v-else class="quill-content" :options="editorOption2">
		</quill-editor>


		<input id="upLoadVideo" style="display: none;" type="file" accept="video/*" @change="filechange" />
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		quillEditor,
		Quill
	} from 'vue-quill-editor' // 富文本组件
	import {
		container,
		ImageExtend,
		QuillWatch
	} from 'quill-image-extend-module' // 富文本组件的模块

	Quill.register('modules/ImageExtend', ImageExtend); // 模块注册

	export default {
		name: "RichText",
		components: {
			quillEditor
		},
		props: {
			value: { // v-model 的值
				type: String,
				default: ''
			},
			height: { // v-model 的值
				type: Number,
				default: 400
			},
			type: {
				type: [String, Number],
				default: () => {
					return 2
				}
			},
			refName: {
				type: String,
				default: 'myQuillEditor'
			},
			placeholder: {
				type: String,
				default: ''
			}
		},
		watch: {
			value: {
				handler() { // 监听value给content赋值
					this.content = this.value;
				},
				immediate: true
			},
			placeholder: {
				handler() { // 监听value给content赋值
					this.$set(this.editorOption, 'placeholder', this.placeholder)
					this.$set(this.editorOption2, 'placeholder', this.placeholder)
				},
				immediate: true
			},
			content() { // 监听content给value赋值,实现双向绑定
				this.$emit('input', this.content)
			}
		},
		data() {
			let that = this;
			const container2 = [
				['bold', 'italic', 'underline', 'strike'],
				['blockquote', 'code-block'],
				[{
					'header': 1
				}, {
					'header': 2
				}],
				[{
					'list': 'ordered'
				}, {
					'list': 'bullet'
				}],
				[{
					'script': 'sub'
				}, {
					'script': 'super'
				}],
				[{
					'indent': '-1'
				}, {
					'indent': '+1'
				}],
				[{
					'direction': 'rtl'
				}],
				[{
					'size': ['small', false, 'large', 'huge']
				}],
				[{
					'header': [1, 2, 3, 4, 5, 6, false]
				}],
				[{
					'color': []
				}, {
					'background': []
				}],
				[{
					'font': []
				}],
				[{
					'align': []
				}],
				['clean'],
				['link', 'image']
			]
			return {
				upDialog: false,
				loadingUp: false,
				fileList: [],
				content: '', // 富文本内容
				editorOption: {
					placeholder: "请输入内容",
					modules: {
						ImageExtend: {
							loading: true,
							name: 'file', // 图片上传字段名
							action: '/admin/upload', // 图片上传地址
							response: (res) => {
								return res.url
							},
							change: (xhr, formData) => {
								formData.append('type', 'img')
								formData.append('is_editor', 2)
							}
						},
						toolbar: {
							container: container, // container为工具栏，此次引入了全部工具栏
							handlers: {
								'image': function() { // 劫持原来的图片点击按钮事件
									QuillWatch.emit(this.quill.id)
								},
								'video': function(value) {
									// that.upDialog=true;
									console.log("=-=-=")
									that.$event.$emit('upVideo', that.type)
								}
							}
						}
					},
				},
				editorOption2: {
					placeholder: "请输入内容",
					modules: {
						ImageExtend: {
							loading: true,
							name: 'file', // 图片上传字段名
							action: '/admin/upload', // 图片上传地址
							response: (res) => {
								return res.url
							},
							change: (xhr, formData) => {
								formData.append('type', 'img')
								formData.append('is_editor', 2)
							}
						},
						toolbar: {
							container: container2, // container为工具栏，此次引入了全部工具栏
							handlers: {
								'image': function() { // 劫持原来的图片点击按钮事件
									QuillWatch.emit(this.quill.id)
								},
								'video': function(value) {
									// that.upDialog=true;
									that.$event.$emit('upVideo', that.type)
								}
							}
						}
					},
				}
			}
		},
		created() {
			this.$event.$on('setVideo', (data) => {
				console.log('类型' + this.type);
				if (this.type == 2) {
					return
				}
				let range = '';
				range = this.$refs.myQuillEditor.quill.getSelection();
				//视频插入在富文本中的位置
				let index = 0;
				if (range == null) {
					index = 0;
				} else {
					index = range.index;
				}
				this.$refs.myQuillEditor.quill.insertEmbed(index, 'video', data);
				this.$event.$emit('closeUp')
				this.$message.success('上传成功！')
			})
		},
		methods: {
			upLoadVideo(){
				document.getElementById('upLoadVideo').click()
			},
			filechange(){
				//改用以前的 
			},
			//上传之前
			beforeUp() {
				this.loadingUp = true
			},
			closeMy() {
				this.upDialog = false
			},
			//上传视频成功
			handleSuccess(response, file, fileList) {
				console.log('类型' + this.type);
				if (this.type == 2) {
					return
				}
				// console.log(file.response.url);
				if (file.status == "success") {
					//当编辑器中没有输入文本时，这里获取到的 range 为 null
					let range = '';
					if (this.editArticleDialog) {

						range = this.$refs.myQuillEditorEdit.quill.getSelection();

					} else {
						range = this.$refs.myQuillEditor.quill.getSelection();
					}

					//视频插入在富文本中的位置
					let index = 0;
					if (range == null) {
						index = 0;
					} else {
						index = range.index;
					}
					//隐藏弹框
					this.upDialog = false;
					this.loadingUp = false
					//将视频链接插入到当前的富文本当中
					if (this.editArticleDialog) {

						this.$refs.myQuillEditorEdit.quill.insertEmbed(index, 'video', file.response.url);

					} else {
						this.$refs.myQuillEditor.quill.insertEmbed(index, 'video', file.response.url);
					}

					this.$message.success('上传成功！')
				} else {
					this.$message.error('上传失败，请重试！')
				}


			},

		}
	}
</script>
<style>

</style>
<style scoped lang="less">
	.quill-component {
		width: 100%;
		margin: auto;
	}

	.quill-content {
		height: 100%;
		display: flex;
		flex-flow: column;
		background-color: #fff;
	}
</style>
